import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './locales/en.json';
import ptTranslation from './locales/pt.json';
import sqTranslation from './locales/sq.json';



const resources = {
  en: {
    translation: enTranslation,
  },
  pt: {
    translation: ptTranslation,
  },
  sq: {
    translation: sqTranslation,
  },
};

i18n.use(initReactI18next)
.init({
  resources,
  lng: "en", // Default language
  fallbackLng: 'en', // Fallback language
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
