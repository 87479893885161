import Image from "next/image";
import IMAGES from "./images";


const languageOptions = [
  {
    label: (
      <span className="label-lang">
        <Image src={IMAGES.EN.src} alt="en" width={25} height={23} /> En
      </span>
    ),
    value: "en"
  },
  {
    label: (
      <span className="label-lang">
        <Image src={IMAGES.PT.src} alt="pt" width={25} height={23}/> Pt
      </span>
    ),
    value: "pt"
  },
  {
    label: (
      <span className="label-lang">
        <Image src={IMAGES.SQ.src} alt="sq" width={25} height={23} /> Al
      </span>
    ),
    value: "sq"
  }
];
export default languageOptions;
